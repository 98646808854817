import { useEffect, useState } from 'react'
import { useAuth } from "../../../../hooks/AuthContext";
import { useParams } from "react-router-dom";
import useAlert from "../../../../hooks/useAlert";
import { PEDIDOCONCLUIDOPAGAMENTOESCOLHIDO } from "../../../../util/typesReactQuery";
import { LoadingPedidoRealizadoPagamentoEscolhido, MenuDashboard, PaginasMenuDashboard, PedidoConcluidoPagamentoEscolhido } from "../../../../components";
import { useQuery } from "react-query";
import TiposPedido from '../../../../util/typesTipoPedido';
import { getPedidoComercialByID } from '../../../../api/PedidoComercial';
import useSeoConfig from '../../../../hooks/useSeoConfig'

export function Confirmacao() {
  const { user } = useAuth();
  const { showAlertError, showAlertSuccess } = useAlert();
  const { idPedido } = useParams()
  const [isGold, setIsGold] = useState(false);
  const { sendPageAtual } = useSeoConfig();

  useEffect(() => {
    sendPageAtual("Pedido_concluido");
  }, [])


  const { data: Pedido } = useQuery([`${PEDIDOCONCLUIDOPAGAMENTOESCOLHIDO}${idPedido}T`], () => getPedidoComercialByID(user.id, idPedido), {
    onSuccess: (data) => {
      if (!data || !data?.data) {
        showAlertError(
          "Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: Nenhum pedido foi encontrado - R9D1A "
        );
        return;
      }

      if (data.errorMessage) {
        showAlertError(
          `Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: ${data.errorMessage} - R3256 `
        );
        return;
      }

      setIsGold(() => (data.data.tipoPedido.id === TiposPedido.VendaOuro))


    },
    onError: (error) => {
      showAlertError(
        `Ops, ocorreu um erro, informe ao suporte tecnico: ${error?.message} -  R98CC`
      );
    },
    refetchOnWindowFocus: false,
  })


  return (
    <>
      <MenuDashboard pagina={isGold ? PaginasMenuDashboard.Ouro : PaginasMenuDashboard.Especie}>
        {!!Pedido?.data ?
          <PedidoConcluidoPagamentoEscolhido pedido={Pedido.data} />
          :
          <LoadingPedidoRealizadoPagamentoEscolhido />
        }
      </MenuDashboard>


    </>
  );
}
