import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  box: {
    borderRadius: 5,
    padding:'0px 20px',
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding:20,
      borderRadius: 5,
      
      width: "100%",
    },
  },
  Alert: {
    marginBottom: 15,
    alignItems: 'center',
    [theme.breakpoints.up("sm")]: {
      marginLeft: 20,
      marginBottom: 20,
      alignItems: 'center',
    },
  },
}));

export default useStyle;
