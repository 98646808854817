import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { blue } from "@mui/material/colors";

const drawerWidth = 270;
const drawerWidthMobile = 'auto';
export const openedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  width: drawerWidthMobile,
  zIndex: 2000,
  paddingRight: 15,
  backgroundColor: "#fcfcfc",
  [theme.breakpoints.up("sm")]: {
    width: drawerWidth,
    zIndex: 2,
    paddingRight: 0,

    backgroundColor: "#fcfcfc",
  },
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(0)} + 1px)`,
  backgroundColor: "#fcfcfc",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
    backgroundColor: "#fcfcfc",
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: 10,
  backgroundColor: "#fcfcfc !important",
  ...theme.mixins.toolbar,
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#fcfcfc",
  color: "#000",
  // boxShadow: "1px 3px 1px #eee",
  boxShadow: "none",
  borderBottom: "",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const useStyle = makeStyles((theme) => ({
  textItemList: {
    "& .MuiListItemText-root span": {
      fontSize: "0.75rem !important",
    },
    "& .MuiListItemIcon-root ": {
      "& svg": {
        fontSize: "1.3rem !important",
      },
    },
  },

  check: {
    position: "relative",
    "& .MuiListItemText-root span": {
      fontWeight: "bold !important",
    },
    "& .MuiListItemIcon-root ": {
      "& svg": {
        fontWeight: "bold !important",
      },
    },
    "&:before": {
      position: "absolute",
      content: '""',
      left: "0.03rem",
      background: theme.palette.primary.main,
      width: ".3rem",
      height: "2rem",
      top: ".5rem",
    },
  },
  DrawerHeader: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
    },
  },
  Logo: {
    width: "7em",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      my: 0,
      alignItems: "left",
    },
  },
  ListItem: {
    [theme.breakpoints.up("sm")]: {
      bottom: "-2vh",
    },
    [theme.breakpoints.up("lg")]: {
      bottom: "0",
    },
    [theme.breakpoints.up("xl")]: {
      bottom: "0",
    },
  },

  subMenuDisabled: {
    opacity: 0.4,
    color: theme.palette.text.disabled,
  },

  subMenu: {
    opacity: 0.8,
  },
  subMenuCheck: {
    opacity: 1,
    "& .MuiSvgIcon-root ": {
      color: theme.palette.primary.main + "!important",
      strokeWidth: "1",
    },
    "& span": {
      color: theme.palette.primary.main + "!important",
    },
  },
  ListItemText: {
    "&:hover": {
      textDecoration: "none",
    },
  },





}));
